/* src/app/_shared/components/base/divider/divider.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
:host {
  display: block;
  margin-top: 36px;
}
:host:first-child {
  margin-top: 0;
}
/*# sourceMappingURL=divider.component.css.map */
