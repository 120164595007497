import { Component } from '@angular/core';

@Component({
  selector: 'pn-divider',
  standalone: true,
  imports: [],
  template: ``,
  styleUrl: './divider.component.scss',
})
export class DividerComponent {}
